import React, { FC } from 'react';
import { navigate } from 'gatsby';
import { toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import { ArticleTeaserProps } from 'gatsby-theme-husky/src/components/ArticleTeaser/models';
import NiceCard from 'gatsby-theme-husky/src/components/NiceCard';
import ButtonPosition from 'gatsby-theme-husky/src/components/NiceCard/CardButtonPositionEnum';

import 'gatsby-theme-husky/src/components/ArticleTeaser/ArticleTeaser.scss';
import './ArticleTeaserOverride.scss';

const ArticleTeaser: FC<ArticleTeaserProps> = ({
  articleBannerImage,
  articleImageAlt,
  teaser,
  title,
  url,
  moreText,
  isLabelsActive,
  label,
  moreAriaLabel,
}) => {
  const topCardContent = {
    cardImage: { imageAlt: articleImageAlt, image: articleBannerImage },
  };
  const bottomCardContent = {
    title,
    titleAsLink: true,
    titleClassname: 'article-list__card--title',
    descriptionClassname: 'article-list__card--description',
    url,
    description: teaser,
    buttons: [
      {
        text: moreText,
        className: 'card--more-link',
        buttonAsLink: true,
        ariaLabel: moreAriaLabel,
        onClick: () => navigate(url),
        position: ButtonPosition.Left,
        variant: { reversed: true },
      },
    ],
  };

  return (
    <div className="article-list__card" data-testid="article teaser">
      {toBoolean(isLabelsActive) ? <div className="article-list__card--label">{label}</div> : null}
      <NiceCard {...{ topCardContent, bottomCardContent }} />
    </div>
  );
};

export default ArticleTeaser;
