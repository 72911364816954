import { Link, graphql } from 'gatsby';
import React from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import Layout from 'gatsby-theme-husky/src/layout/Layout/Layout';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import Banner from 'gatsby-theme-husky/src/common/Banner';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';
import ArticleList from 'gatsby-theme-husky/src/components/ArticleList';
import YouTubeLazyLoaded from '../../components/YouTubeLazyLoaded';

import './TipsAndTricksListeningPageOverride.scss';

const TipsAndTricksListeningPage = ({
  data: {
    allUmbracoTipsAndTricksListing: { nodes },
    allUmbracoArticles: { nodes: umbracoArticlesNodes },
    umbracoVideosYoutube: { nodes: umbracoVideos },
  },
}) => {
  const {
    langProps,
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    articlesMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
    articleListSettings,
    articleTitle,
    articleDescription,
    videoTitle,
    videoDescription,
    buttonVideo,
    buttonArticles,
  } = nodes[0];

  return (
    <Layout langProps={{ ...langProps }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang: langProps.lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Banner
        {...{
          content,
          bannerVariant,
          imageAsBackground,
          imageAsBackgroundAlt,
        }}
      />
      <div className="listening-page">
        <div className="listening-page__content">
          <h2 className="listening-page__title">{articleTitle}</h2>
          <div className="listening-page__text">
            <DangerouslySetInnerHtml html={articleDescription} />
          </div>
          <div className="listening-page__articles">
            <ArticleList
              {...{
                articleListSettings,
                umbracoArticlesNodes,
                loadAllArticlesLink: buttonVideo.url,
              }}
              showAllArticles
            />
          </div>
          <div className="listening-page__btn-container flex-x-center">
            <Link className="btn" to={buttonArticles.url}>
              {buttonArticles.name}
            </Link>
          </div>
        </div>

        <div className="listening-page__content">
          <h2 className="listening-page__title">{videoTitle}</h2>
          <div className="listening-page__text">
            <DangerouslySetInnerHtml html={videoDescription} />
          </div>
        </div>
        <div className="video__content">
          {umbracoVideos.map(({ videoId, imagePoster, name }) => {
            return (
              <div className="video__item" key={videoId}>
                <YouTubeLazyLoaded {...{ videoId }} videoPoster={imagePoster} />
                <p className="video__title">{name}</p>
              </div>
            );
          })}
        </div>
        <div className="listening-page__btn-container flex-x-center">
          <Link className="btn" to={buttonVideo.url}>
            {buttonVideo.name}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String, $parentId: Int, $numberOfVideos: Int) {
    umbracoVideosYoutube: allUmbracoVideoYoutube(limit: $numberOfVideos) {
      nodes {
        name
        videoId
        imagePoster {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
      }
    }
    allUmbracoTipsAndTricksListing(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        id
        url
        langProps {
          lang
        }
        articleTitle
        articleDescription
        videoTitle
        videoDescription
        buttonVideo {
          name
          url
        }
        buttonArticles {
          name
          url
        }
        articleListSettings {
          sectionTitle
          loadAllArticlesButtonText
          moreText
          moreAriaLabel
          numberOfArticles
          isLabelsActive
        }
        articlesMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
        seoProps {
          seoMetaTitle
          seoMetaDescription
          seoMetaKeywords
          seoExternalHreflangs {
            value
            key
          }
        }
      }
    }
    allUmbracoArticles(
      filter: { parentId: { eq: $parentId }, langProps: { lang: { eq: $lang } } }
      limit: 2
    ) {
      nodes {
        langProps {
          lang
        }
        articleThumbnailImage {
          fallbackUrl
          fluid {
            base64
            originalImg
            srcSet
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        articleBannerImageAltText
        url
        title
        teaser
        label
        tags {
          articleTag
          id
        }
      }
    }
  }
`;

export default TipsAndTricksListeningPage;
