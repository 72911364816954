import React, { FC } from 'react';
import { navigate } from 'gatsby';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import Button from 'gatsby-theme-husky/src/common/Button';
import ArticleTeaser from 'gatsby-theme-husky/src/components/ArticleTeaser/ArticleTeaser';
import { ArticleListProps } from 'gatsby-theme-husky/src/components/ArticleList/models';

import './ArticleListOverride.scss';

const ArticleList: FC<ArticleListProps> = ({
  articleListSettings: {
    sectionTitle,
    sectionSubtitle,
    numberOfArticles,
    isLabelsActive,
    moreText,
    loadAllArticlesButtonText,
    moreAriaLabel,
  },
  umbracoArticlesNodes,
  loadAllArticlesLink,
  showAllArticles = false,
}) => {
  const articleList = showAllArticles ? umbracoArticlesNodes : umbracoArticlesNodes.slice(0, 18);

  return (
    <section data-testid="article list" className="article-list">
      {!showAllArticles ? <h2 className="article-list__title">{sectionTitle}</h2> : null}
      <DangerouslySetInnerHtml html={sectionSubtitle} />
      <div className="article-list__row">
        {articleList.map((node) => (
          <ArticleTeaser
            key={node.title}
            {...{
              ...node,
              articleBannerImage: node.articleThumbnailImage,
              articleImageAlt: node.articleBannerImageAltText,
              isLabelsActive,
              moreText,
              moreAriaLabel,
            }}
          />
        ))}
      </div>
      {!showAllArticles && umbracoArticlesNodes.length > (numberOfArticles || 3) ? (
        <Button
          className="article-list__load-all-button d-flex"
          ariaLabel={loadAllArticlesButtonText}
          onClick={() => navigate(loadAllArticlesLink!)}
        >
          {loadAllArticlesButtonText}
        </Button>
      ) : null}
    </section>
  );
};

export default ArticleList;
